<template>
<div class="TrainingDL">
   <article class="l">
      <span class="articleTitle">
         {{ $t('AutoDL.DL.TrainingDL.mba1u4') }}
      </span>
      <div class="detailsBox">
         <div class="divBox">
            <p class="l">
               {{ $t('Training.radioTitle10') }}
            </p>
            <span class="r" style="height: 55px;">
               <el-form v-if="!task_id_DL">
                  <el-form-item :error="name.error">
                     <el-input
                        v-model="name.value"
                        size="mini"
                        @input="ValueChange"
                        :placeholder="$t('qing-shu-ru-ming-cheng')"></el-input>
                  </el-form-item>
               </el-form>
               <span v-else>
                  {{name.value}}
               </span>
            </span>
         </div>
         <div class="divBox">
            <p class="l">
               {{$t('AutoDL.DL.TrainingDL.716mgp')}}
            </p>
            <span class="r">{{dataset_id_DL}}</span>
         </div>
         <div class="divBox">
            <p class="l">
               {{ $t('AutoDL.DL.TrainingDL.98yku9') }}
            </p>
            <span class="r">
               <el-input-number
                  style="width:100px"
                  :disabled="startVisible"
                  controls-position="right"
                  size="small"
                  v-model="trainingData.max_trials"
                  :min="1"
                  :max="100"
                  :step="1" />
            </span>
         </div>
         <div class="divBox">
            <p class="l">
               {{ $t('AutoDL.DL.TrainingDL.mv6pgk') }}
            </p>
            <span class="r">
               <el-input-number
                  style="width:100px"
                  :disabled="startVisible"
                  controls-position="right"
                  size="small"
                  v-model="trainingData.epoch"
                  :min="1"
                  :max="100"
                  :step="1" />
            </span>
         </div>
         <div class="divBox">
            <p class="l">
               Batch Size
            </p>
            <span class="r">
               <el-input-number
                  style="width:100px"
                  :disabled="startVisible"
                  controls-position="right"
                  size="small"
                  v-model="trainingData.batch_size"
                  :min="2"
                  :max="64"
                  :step="1" />
            </span>
         </div>
         <div class="divBox">
            <p class="l">
               Patience
            </p>
            <span class="r">

               <el-input-number
                  style="width:100px"
                  :disabled="startVisible"
                  controls-position="right"
                  size="small"
                  v-model="trainingData.patience"
                  :min="2"
                  :max="10"
                  :step="1" />
            </span>
         </div>
         <div class="divBox">
            <p class="l">
               {{$t('AutoDL.DL.TrainingDL.q5zpiq')}}
            </p>
            <span class="r">
               <el-input-number
                  style="width:100px"
                  :disabled="startVisible"
                  controls-position="right"
                  size="small"
                  v-model="trainingData.resize_h"
                  :min="50"
                  :max="5000"
                  :step="1" />
            </span>
         </div>
         <div class="divBox">
            <p class="l">
               {{$t('AutoDL.DL.TrainingDL.3nznyv') }}
            </p>
            <span class="r">
               <el-input-number
                  style="width:100px"
                  :disabled="startVisible"
                  controls-position="right"
                  size="small"
                  v-model="trainingData.resize_w"
                  :min="50"
                  :max="5000"
                  :step="1" /></span>
         </div>
         <!-- <div class="divBox">
            <p class="l">
               GPU数量
            </p>
            <span class="r">
               <el-input-number
                  style="width:100px"
                  :disabled="startVisible"
                  controls-position="right"
                  size="small"
                  v-model="trainingData.gpu_ids"
                  :min="0"
                  :max="1"
                  :step="1" /></span>
         </div> -->
         <!-- <div><p class="l">选择的特征名称</p><span class="r">{{FeatureName}}</span></div> -->

         <div class="divBox">
            <p class="l">
               {{ $t('AutoDL.DL.TrainingDL.h3d26k') }}
            </p>
            <span class="r">
               <el-input-number
                  style="width:100px"
                  :disabled="startVisible"
                  controls-position="right"
                  size="small"
                  v-model="trainingData.validation_split"
                  :min="0"
                  :max="0.5"
                  :step="0.01" /></span>
         </div>
         <div class="divBox">
            <p class="l">
               {{ $t('AutoDL.DL.TrainingDL.7qh2xm') }}
            </p>
            <span class="r">
               <el-select size="small" v-model="trainingData.train_folder_path" @change="$forceUpdate()">
                  <el-option
                     v-for="item in train_folder_path"
                     :key="item"
                     :value="item">
                  </el-option>
               </el-select>
            </span>
         </div>
         <div class="divBox">
            <p class="l">
               {{ $t('AutoDL.DL.TrainingDL.o3novl') }}
            </p>
            <span class="r">
               <el-switch
                  :disabled="startVisible"
                  v-model="trainingData.is_overwrite"
                  :active-value="true"
                  :inactive-value="false">
               </el-switch>
            </span>
         </div>
         <div class="divBox">
            <p class="l">
               {{ $t('AutoDL.DL.TrainingDL.n39qfd') }}
            </p>
            <span class="r">
               <el-switch
                  :disabled="startVisible"
                  v-model="trainingData.is_shuffle"
                  :active-value="true"
                  :inactive-value="false">
               </el-switch>
            </span>
         </div>
      </div>

      <!-- <div class="Slider">
         <h3>
            验证集的训练数据比例
         </h3>
         <el-slider
            :disabled="startVisible"
            v-model="featureParam.correlation_threshold"
            :format-tooltip="formatTooltip" />
         <el-input-number
            :disabled="startVisible"
            controls-position="right"
            size="small"
            v-model="trainingData.validation_split"
            :min="0"
            :max="0.5"
            :step="0.01" />
      </div> -->

   </article>
   <aside class="r">
      <p class="asideMainTitle">
         <!-- 日志详情 -->
         {{$t('Analysis.asideMainTitle')}}
      </p>
      <div class="asideMain">
         <el-tooltip
            :content="$t('Training.tooltip')"
            placement="top-start"
            v-if="!startVisible">
            <i class="el-icon-document-copy" v-if="!startVisible" @click="Copy()" />
         </el-tooltip>
         <ul>
            <li v-for="item in logMsg" :key="item.message">
               {{ item }}
            </li>
         </ul>
      </div>
      <el-button
         plain
         class="analyse"
         :disabled="startVisible"
         @click="creatModel">
         <i class="el-icon-video-play" />
         {{ $t('Training.button1') }}
      </el-button>
      <el-button
         plain
         class="analyse"
         :disabled="stopVisible"
         @click="stopTraining">
         <i class="el-icon-video-pause" />
         {{ $t('Training.button2') }}
      </el-button>
   </aside>
   <section>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonTop')"
         placement="top-start"
         :hide-after="200">
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-left"
            :disabled="lastVisible"
            circle
            @click="routerPush('AutoDL')" />
      </el-tooltip>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonNext')"
         placement="top-start">
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-right"
            :disabled="nextVisible"
            circle
            @click="routerPush('PredictionDL')" />
      </el-tooltip>
   </section>
</div>
</template>

<script>
import {
   sendWebsocket,
   closeWebsocket
} from "@/tools/websocket.js";
import {
   mapState
} from "vuex";
import moment from "moment";

export default {
   beforeDestroy() {
      // 页面销毁时关闭ws。因为有可能ws连接接收数据尚未完成，用户就跳转了页面
      // 在需要主动关闭ws的地方都可以调用该方法
      closeWebsocket();
   },
   watch: {
      "featureParam.correlation_threshold": function (val) {
         this.trainingData.validation_split = val / 100;
      },
      "trainingData.validation_split": function (val) {
         this.featureParam.correlation_threshold = val * 100;
      },
      task_code: "handleTaskStatus", // 监听任务状态值
   },
   computed: {
      ...mapState([
         "task_id_DL",
         "dataset_id_DL",
      ]),
   },
   data() {
      return {
         featureParam: {
            correlation_threshold: 20,
         },
         startVisible: false, //开始分析的状态
         stopVisible: true, //停止分析的状态
         nextVisible: true, //下一步的状态
         lastVisible: false, //上一步的状态
         trainingData: {
            max_trials: 1,
            epoch: 3,
            batch_size: 32,
            patience: 3,
            resize_h: 180,
            resize_w: 180,
            seed: 100,
            validation_split: 0.2,
            // gpu_ids: "1",
            is_overwrite: true,
            is_shuffle: true
         },
         name: {
            value: '',
            error: ''
         },
         logMsg: [],
         task_code: '',
         train_folder_path:[]
      };
   },
   mounted() {
      this.getTask()
      this.get_train_folder_path()
   },
   methods: {
      getTask() {
         if (this.task_id_DL) {
            let url = `${window.apis.AUTODL_TASK}${this.task_id_DL}/`
            this.axios(url).then(
               res => {
                  this.name.value = res.data.task_name

                  let train_output = JSON.parse(res.data.train_output);
                  let task_status = train_output.task_status;
                  let msg = train_output.msg;

                  if (task_status === 1) {
                     this.logMsg.push(this.$t('Analysis.message'));
                     this.requstWs();
                  } else if (task_status === 2) {
                     // 执行失败
                     this.logMsg.push(msg);
                     this.task_code = 2;
                  } else if (task_status === 3) {
                     // 执行成功
                     this.logMsg.push(msg);
                     this.task_code = 3;
                  } else {
                     console.log("" + task_status);
                  }
               }
            )
         }
      },
      get_train_folder_path(){
         this.axios(`${window.apis.IMG_DATASET}${this.dataset_id_DL}/`).then(res => {
            this.train_folder_path = this.toJSON(res.data.train_folder_path)
            let data = this.train_folder_path[0]
            this.trainingData.train_folder_path = JSON.parse(JSON.stringify(data));

            })
      },
      routerPush(name) {
         this.$router.push({
            name: name,
         });
      },
      stopTraining() {
         // 正在停止中...”
         this.logMsg.push(this.$t('Analysis.message4'));
         let url = window.apis.KERAS_TRAIN;
         this.axios
            .post(url, null, {
               params: {
                  task_id: this.task_id_DL,
                  action: "stop",
               },
            })
            .then((res) => {
               console.log(res);
               if (res.status === 200 || res.status === 201) {
                  if (res.data.task_code == 1) {
                     this.task_code = 2;
                  }
               }
            })
      },
      handleTaskStatus() {
         if (this.task_code === 1) {
            // 控制按钮显示
            (this.startVisible = true), //开始训练
            (this.stopVisible = false), //停止训练
            (this.nextVisible = true), //下一步的状态
            (this.lastVisible = true); //上一步的状态
         } else if (this.task_code === 0) {
            // 控制按钮显示
            (this.startVisible = false), //开始训练
            (this.stopVisible = true), //停止训练
            (this.nextVisible = false), //下一步的状态
            (this.lastVisible = false); //上一步的状态
         } else if (this.task_code === 2) {
            // 控制按钮显示
            (this.startVisible = false), //开始分析的状态
            (this.stopVisible = true), //停止分析的状态
            (this.nextVisible = true), //下一步的状态
            (this.lastVisible = false); //上一步的状态
         } else if (this.task_code === 3) {
            // 控制按钮显示
            (this.startVisible = false), //开始分析的状态
            (this.stopVisible = true), //停止分析的状态
            (this.nextVisible = false), //下一步的状态
            (this.lastVisible = true); //上一步的状态
         } else if (this.task_code === -1) {
            // 控制按钮显示
            (this.startVisible = false), //开始分析的状态
            (this.stopVisible = true), //停止分析的状态
            (this.nextVisible = true), //下一步的状态
            (this.lastVisible = false); //上一步的状态
         } else {
            // 其他默认失败
            (this.startVisible = false), //开始分析的状态
            (this.stopVisible = true), //停止分析的状态
            (this.nextVisible = true), //下一步的状态
            (this.lastVisible = false); //上一步的状态
         }
      },
      ValueChange() {
         if (this.name.value.search(/^[\u4E00-\u9FA5A-Za-z0-9][\S]+$/)) {
            this.name.error = this.$t('Analysis.message6')
         } else {
            this.name.error = ''
         }
      },
      creatModel() {
         if (!this.task_id_DL) {
            if (this.name.value.search(/^[\u4E00-\u9FA5A-Za-z0-9][\S]+$/)) {
               this.name.error = this.$t('Analysis.message6')
               return
            } else {
               this.name.error = ''
            }
            let url = `${window.apis.AUTODL_TASK}${this.dataset_id_DL}/`;
            let config = {
               task_name: this.name.value
            }
            this.axios.post(url, config).then(res => {
               this.$store.commit("task_id_DL", res.data.id);
               this.beforeTiaining()
            }).catch(() => {
               console.log(11)
               this.$store.commit("task_id_DL", '');
            })
         } else {
            this.beforeTiaining()
         }
      },
      beforeTiaining() {
         let url = `${window.apis.KERAS_TRAIN}?task_id=${this.task_id_DL}&action=start`;
         this.trainingData.dataset_id = this.dataset_id_DL
         // this.trainingData.gpu_ids = this.trainingData.gpu_ids.toString()

         this.axios.post(url, this.trainingData).then(res => {
            this.requstWs()
            console.log(res)
         })
      },
      scrollToBottom() {
         this.$nextTick(() => {
            let asideMain = this.$el.querySelector(".asideMain");
            asideMain.scrollTop = asideMain.scrollHeight;
         });
      },
      // ws连接成功，后台返回的ws数据，组件要拿数据渲染页面等操作
      wsMessage(data) {
         this.task_code = 1
         // 这里写拿到数据后的业务代码
         if (data.t === 1) {
            // 日志
            this.logMsg.push(data.p);
            this.scrollToBottom();
         }
         if (data.t === 2) {
            // -2:未知状态, -1: 执行失败, 0: 执行成功, 1: 执行中, 2: 停止
            // 根据返回值，修改对应的 task_code: -2,-1,2 只有开始按钮可以点击，0只有停止按钮不可以点击，1只有停止按钮可以点击
            if (data.p == -2 || data.p == -1 || data.p == 2) {
               this.task_code = 2;
            }
            if (data.p == 0) {
               this.task_code = 3;
            }
            if (data.p == 1) {
               this.task_code = 1;
               // 插入“正在执行中...”
               this.logMsg.push(this.$t('Analysis.message'));
            }
         }
      },
      // ws连接失败，组件要执行的代码
      wsError() {
         // 比如取消页面的loading
         // 失败处理
      },
      requstWs() {
         // 防止用户多次连续点击发起请求，所以要先关闭上次的ws请求。
         closeWebsocket();
         // 跟后端协商，需要什么参数数据给后台
         const obj = {};
         // 发起ws请求

         sendWebsocket(
            `${window.apis.WS_SERVER}autokeras_${this.task_id_DL}`,
            obj,
            this.wsMessage,
            this.wsError
         );
      },

      // methode转JSON
      toJSON(str) {
         try {
            return JSON.parse(str)
         } catch (err) {
            return str
         }
      },
      Copy() {
         // 循环遍历this.logMsg
         for (var key in this.logMsg) {
            this.CopyData.push(this.logMsg[key]);
            this.CopyData.push(" \n");
         }
         var a = this.CopyData.join("");
         this.$copyText(a);
         this.$message({
            message: this.$t('Training.message3'),
            type: "success",
         });
      },

   },
};
</script>

<style lang="scss" scoped>
.TrainingDL {
   min-width: 1165px;
   margin-top: 30px;
   overflow: hidden;
}

.el-form-item {
   margin: 0;

   .el-form-item__content {
      line-height: 0;
   }
}

::v-deep .el-form-item__content {
   line-height: 0;
}

.l {
   height: auto;
}

.minDetailsBox {
   width: 92.5%;
   margin: 0 0 1px 20px;
   font-size: 14px;
   display: flex;
   justify-content: space-between;
   flex-direction: row;
   flex-wrap: wrap;

   .divBox {
      // width: 47%;
      height: auto;
      padding-bottom: 15px;
      overflow: hidden;

      .el-input {
         width: 100px;
      }

      .el-select {
         width: 100px;
      }

      h3 {
         display: block;
         font-weight: 500;
         font-size: 14px;
         line-height: 32px;
         width: 105px;
         text-align: center;
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
         margin-right: 10px;
      }
   }
}

section {
   position: fixed;
   right: 100px;
   bottom: 50px;
   z-index: 9999;

   .Step {
      margin: 0 15px;
      box-shadow: 0px 0px 7px #333333;
   }
}

.Slider {
   width: auto;
   height: auto;
   display: inline-block;
   margin: 0px 0 0 20px;
   position: relative;

   .inputName {
      margin: 10px 0;
   }

   h3 {
      display: block;
      font-weight: 500;
      font-size: 14px;
   }

   .el-slider {
      display: inline-block;
   }

   .el-input-number {
      display: inline-block;
      overflow: hidden;
      margin-left: 31px;
   }
}

.el-slider {
   width: 290px;
}

h3 {
   display: inline-block;
   font-size: 20px;
   font-weight: bold;
   color: #333333;
}

article {
   width: 490px;
   height: 420px;
   border: 1px solid rgba(218, 223, 231, 0.5);
   padding-bottom: 10px;
   text-align: left;
   overflow: hidden;

   .articleTitle {
      display: block;
      width: 100%;
      height: 47px;
      border-bottom: 1px solid rgba(218, 223, 231, 0.5);
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 47px;
      padding-left: 20px;
   }

   .detailsBox {
      width: 100%;
      margin-top: 10px;
      font-size: 14px;
      // border: 1px solid rgba(218, 223, 231, 0.5);

      .divBox {
         width: 100%;
         height: auto;
         padding: 5px 10px;
         overflow: hidden;
         border-bottom: 1px solid rgba(218, 223, 231, 0.5);

         p {
            width: 35%;
            font-weight: 500;
         }

         span {
            width: 55%;
            text-align: left;
         }
      }

      div:hover {
         background: rgba(246, 247, 248, 0.5);
      }

      div:last-of-type {
         border: 0px;
      }
   }
}

aside {
   position: relative;
   // width: auto;
   max-width: 1250px;
   height: 420px;
   margin-bottom: 98px;

   .asideMainTitle {
      display: block;
      width: 100%;
      height: 47px;
      background: #ffffff;
      border: 1px solid rgba(218, 223, 231, 0.5);
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 47px;
      text-align: left;
      padding-left: 20px;
   }

   .asideMain {
      width: calc(100vw - 820px);
      min-width: 635px;
      height: 372px;
      border: 1px solid rgba(218, 223, 231, 0.5);
      background: rgba(0, 0, 0, 0.8);
      overflow: auto;

      i {
         position: absolute;
         top: 70px;
         right: 30px;
         color: rgba($color: #ffffff, $alpha: 1);
         font-size: 20px;
         cursor: pointer;
         transition: all 200ms;
      }

      i:hover {
         color: rgba($color: #ffffff, $alpha: 0.6);
      }

      ul li {
         width: auto;
         list-style: none;
         text-align: left;
         color: #ffffff;
         font-size: 12px;
         font-family: monospace;
         white-space: pre;
         word-wrap: normal;
         -webkit-font-variant-ligatures: contextual;
         font-variant-ligatures: contextual;
      }
   }

   .analyse {
      margin: 20px;
   }
}
</style>
